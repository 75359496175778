
import { defineComponent } from "vue";

export default defineComponent({
  name: "VDropdownList",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as () => any[],
      default: () => []
    }
  },
  methods: {
    onItemSelection(item: any): void {
      this.$emit("selected", item);
    }
  }
});
