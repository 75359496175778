
import { CSSProperties, defineComponent } from "vue";
// import VButton from "@/components/base/VButton.vue";

interface ItemType {
  value: string;
  name: string;
}

interface LocalItemType {
  value: string;
  name: string;
  isActive: boolean;
}

interface Data {
  vm: string | string[];
  localItems: LocalItemType[];
  errorBucket: string[];
  invalid: boolean;
  radioSelectedItemIndex: number;
}
// class="absolute top-0 left-0 block h-100 bg-primary1 rounded-3xl"
//  focus:ring-2 focus:outline-none focus:ring-white ring-inset
export default defineComponent({
  components: {
    // VButton,
  },
  name: "VButtonGroupSlider",
  props: {
    modelValue: {
      type: [String, Array as () => string[]],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: "Select an item"
    },
    mode: {
      type: String,
      default: "radio",
      required: false,
      validator: (value: string) => ["radio", "checkbox"].includes(value)
    },
    items: {
      type: Array as () => Array<ItemType>,
      required: true,
      default: () => []
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data(): Data {
    return {
      vm: "",
      localItems: [],
      errorBucket: [],
      invalid: false,
      radioSelectedItemIndex: 0
    };
  },
  mounted() {
    this.vm = this.getVMDefaultValue();
    this.assignButtonStates();
  },
  computed: {
    isRadio(): boolean {
      return this.mode === "radio" ? true : false;
    },
    isCheckbox(): boolean {
      return this.mode === "checkbox" ? true : false;
    },
    buttonSelectedClasses() {
      return ``;
    },
    calcButtonWidth(): number {
      return 100 / this.items.length;
    },
    indicatorStyleObj(): CSSProperties {
      if (this.radioSelectedItemIndex === null) {
        return {};
      } else {
        return {
          transition: "transform 0.25s cubic-bezier(.84,.05,.62,1.03)",
          width: `${this.calcButtonWidth}%`,
          transform: `translateX(${100 * this.radioSelectedItemIndex}%)`
        };
      }
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (Array.isArray(this.modelValue)) {
        this.vm = this.modelValue.map((val: string) => val);
      } else {
        this.vm = this.modelValue;
      }
    },
    items(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.assignButtonStates();
    }
  },
  methods: {
    getVMDefaultValue(): string | string[] {
      if (this.isRadio && typeof this.modelValue === "string") {
        return this.modelValue ? this.modelValue : "";
      } else if (this.isCheckbox && Array.isArray(this.modelValue)) {
        return this.modelValue ? this.modelValue.map((val: string) => val) : [];
      } else {
        return "";
      }
    },
    assignButtonStates() {
      // Assigns active class to the buttons which are selected
      this.localItems = [];
      this.items.forEach((item, index) => {
        if (this.isRadio) {
          if (item.value === this.vm) {
            this.radioSelectedItemIndex = index;

            this.localItems.push({
              ...item,
              ...{
                isActive: true
              }
            });
          } else {
            this.localItems.push({
              ...item,
              ...{
                isActive: false
              }
            });
          }
        } else if (this.isCheckbox && Array.isArray(this.vm)) {
          if (this.isValueExists(item.value, this.vm)) {
            this.localItems.push({
              ...item,
              ...{
                isActive: true
              }
            });
          } else {
            this.localItems.push({
              ...item,
              ...{
                isActive: false
              }
            });
          }
        }
      });
    },
    handleClick(index: number): void {
      if (this.isRadio) {
        this.vm = this.items[index].value;
      } else if (this.isCheckbox) {
        if (Array.isArray(this.vm)) {
          if (this.isValueExists(this.items[index].value, this.vm)) {
            // If the item is already selected, remove it from the selected items
            const foundIndex = this.vm.findIndex(
              val => val === this.items[index].value
            );
            this.vm.splice(foundIndex, 1);
          } else {
            this.vm.push(this.items[index].value);
          }
        }
      }
      this.$emit("update:modelValue", this.vm);
      this.invalid = false;
      this.assignButtonStates();
    },
    isValueExists(itemText: string, items: string[]): boolean {
      if (Array.isArray(items)) {
        const foundValue = items.find(val => val === itemText);
        return foundValue ? true : false;
      } else {
        return false;
      }
    }
  }
});
