
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyDeclaration",
  props: {
    privacy: {
      type: Boolean,
      default: false
    },
    marketing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    onPrivacy(value: any) {
      this.$emit("update:privacy", value);
    },
    onMarketing(value: any) {
      this.$emit("update:marketing", value);
    }
  }
});
