
import { defineComponent } from "vue";

enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary"
}

export default defineComponent({
  name: "VButton",
  props: {
    type: {
      type: String as () => ButtonType,
      required: false,
      validator: (value: ButtonType) => Object.values(ButtonType).includes(value),
      default: ButtonType.Primary
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonTypeClasses(): string {
      if (this.type === ButtonType.Primary) {
        return "px-6 h-11 rounded-3xl border text-white bg-primary1 border-primary1 hover:bg-primary1_darker";
      } else if (this.type === ButtonType.Secondary) {
        return "px-6 h-11 rounded-3xl border border-primary1 text-primary1";
      } else if (this.type === ButtonType.Tertiary) {
        return "underline";
      } else {
        return "";
      }
    }
  }
});
