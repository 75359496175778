<template>
  <div class="px-4 rounded-sm shadow-input" :class="{ slidedown: isActive, slideup: !isActive }">
    <ul>
      <li
        class="py-3 border-b cursor-pointer border-dropdown_list_divider last:border-0"
        v-for="(item, i) in items"
        :key="i"
        v-bind:data-id="i"
        @click="onItemSelection(item)"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VDropdownList",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as () => any[],
      default: () => []
    }
  },
  methods: {
    onItemSelection(item: any): void {
      this.$emit("selected", item);
    }
  }
});
</script>

<style scoped>
div {
  -webkit-tap-highlight-color: transparent;
}
</style>
