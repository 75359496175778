
import { defineComponent } from "vue";

import Footer from "@/components/shared/Footer.vue";

export default defineComponent({
  components: {
    Footer
  },
  name: "EmptyLayout"
});
