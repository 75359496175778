import axios, { AxiosRequestConfig } from "axios";
import { v4 as uuid } from "uuid";

const useApi = () => {
  const apiConfig: AxiosRequestConfig = {
    baseURL: process.env.VUE_APP_ROOT_URL,
    headers: {
      "Content-Type": "application/json",
      "X-IBM-Client-Id": process.env.VUE_APP_CLIENT_ID,
      "x-correlation-id": uuid()
    }
  };

  const api = axios.create(apiConfig);

  return { api };
};

export default useApi;
