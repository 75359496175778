<template>
  <div class="rounded-md bg-privacy_bg">
    <div class="flex items-center w-full p-4">
      <v-checkbox
        class="inline-block"
        label=""
        data-testid="privacy-declaration"
        :modelValue="privacy"
        @update:modelValue="onPrivacy"
      ></v-checkbox>
      <p @click.prevent="isOpen = !isOpen" class="ml-4 cursor-pointer">
        I acknowledge that I have read and/or provided a copy of the
        <span class="underline text-primary1">Privacy Declaration</span> to the
        customer.
      </p>

      <svg
        @click="isOpen = !isOpen"
        class="flex-shrink-0 inline-block w-6 h-6 ml-auto transition-transform duration-500 cursor-pointer text-primary1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        :class="isOpen ? 'transform rotate-180' : ''"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
    <div
      v-show="isOpen"
      class="p-4"
      :class="{ slidedown: isOpen, slideup: !isOpen }"
    >
      <div class="overflow-y-scroll max-h-40">
        <div class="px-6">
          <p class="intro">
            In this declaration, "Heartland", "we" or "us" means Heartland Bank
            Limited, and its related entities, successors, assigns, agents and
            associates, and "you" means the person completing this application,
            each person named in this application, each guarantor of any loan
            made to you and any authorised person or beneficial owner of the
            borrower or any guarantor of any loan made to you (as applicable).
          </p>
          <ul class="py-6 list-disc privacy-topics">
            <li>
              Heartland is collecting information about you in accordance with
              the Privacy Act 2020 and our Privacy Statement, and we may not be
              able to provide you with products or services if you do not
              provide that information.
            </li>
            <li>
              That information may be used by us:
              <ul>
                <li>
                  to consider this application for an account or service,
                  including to establish and verify your identity and to assess
                  your creditworthiness and financial position from time to time
                </li>
                <li>
                  to consider any future application for products or services
                  which involves you.
                </li>
              </ul>
            </li>
            <li>
              We can also use it:
              <ul>
                <li>
                  to administer and monitor products or services provided to you
                </li>
                <li>
                  to comply with legal and regulatory requirements (including
                  identity verification requirements)
                </li>
                <li>
                  to provide you with information about other products or
                  services, including those of selected third parties
                </li>
                <li>generally, to develop and run our business; and</li>
                <li>as otherwise described in our Privacy Statement.</li>
              </ul>
            </li>
            <li>
              You agree that – for those purposes – we can provide information
              about you to, and obtain information about you from, other
              organisations or people we consider appropriate. Those
              organisations might include our service providers, other financial
              and insurance institutions, government departments, your employer
              or accountant, third parties for the purposes of fraud prevention,
              identity verification, and any other purposes relevant to those
              purposes (those third parties may retain information and use it
              for identity verification and fraud detection purposes), and other
              appropriate persons.
            </li>
            <li>
              We may also exchange information about you (including default
              information) with credit reporting agencies on an ongoing basis.
              Those agencies may retain that information and provide it to other
              customers who use their credit reporting services.
            </li>
            <li>
              You have rights to access and request correction of your personal
              information under the Privacy Act 2020. You can do so by
              contacting us using the details provided on
              <a target="_blank" href="https://www.heartland.co.nz/contact-us"
                >heartland.co.nz/contact-us</a
              >
            </li>
            <li>
              By proceeding, you confirm that:
              <ul>
                <li>
                  each person named in this application form has read and agrees
                  to the terms above and our Privacy Statement;
                </li>
                <li>
                  all information provided to us is correct, complete and not
                  misleading; and
                </li>
                <li>none of those people is an un-discharged bankrupt.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="px-2">
          <v-checkbox
            class="inline-block"
            name="marketing-optout"
            label="If you do not wish to receive marketing material about Heartland products, please tick this box."
            :modelValue="marketing"
            @update:modelValue="onMarketing"
          ></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyDeclaration",
  props: {
    privacy: {
      type: Boolean,
      default: false
    },
    marketing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    onPrivacy(value: any) {
      this.$emit("update:privacy", value);
    },
    onMarketing(value: any) {
      this.$emit("update:marketing", value);
    }
  }
});
</script>

<style></style>
