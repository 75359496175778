<template>
  <div class="w-full py-3">
    <slot name="label">
      <label v-if="label" :for="$attrs.name" class="block mb-2">{{ label }}</label>
    </slot>
    <div class="relative">
      <input
        @blur="onBlur"
        @focus="onFocus"
        v-bind="$attrs"
        :value="modelValue"
        class="block w-full rounded-md appearance-none h-11 focus:outline-none shadow-input hover:shadow-input_hover focus:ring-1 focus:ring-primary1"
        :class="{
          invalid: !isValid,
          ['ring-1 ring-error']: errors.length,
          'px-8': prefix,
          'px-4': !prefix
        }"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <slot name="prefix">
        <v-icon
          v-if="prefix"
          :name="prefixIcon"
          class="absolute inset-0 w-5 h-5 my-auto ml-2"
        ></v-icon>
      </slot>
    </div>

    <LinearLoader :is-loading="isLoading" />
    <template :key="i" v-for="(error, i) in errors">
      <p class="text-error">{{ error }}</p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, getCurrentInstance, onBeforeUnmount } from "vue";

import LinearLoader from "@/components/shared/LinearLoader.vue";

import { ModelValue } from "@/types/utils";
import useInputValidation from "@/composables/utils/useInputValidation";

export default defineComponent({
  inheritAttrs: false,
  name: "VInput",
  components: {
    LinearLoader
  },
  props: {
    label: {
      type: String,
      default: "Label",
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: ""
    },
    rules: {
      type: Array as () => ((modelValue: ModelValue) => string | boolean)[],
      required: false,
      default: () => []
    },
    onBlurValidation: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: Boolean,
      default: false
    },
    prefixIcon: {
      type: String,
      default: ""
    },
    isCurrency: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vm: null as any
    };
  },
  setup(props) {
    const { isValid, errors, validateRules } = useInputValidation(props.rules);

    onMounted(() => {
      const register = inject<any>("register");
      register(getCurrentInstance());
    });

    onBeforeUnmount(() => {
      const unregister = inject<any>("unregister");
      unregister(getCurrentInstance());
    });

    return {
      isValid,
      errors,
      validateRules
    };
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.isCurrency) {
        this.vm = Number(newValue?.toString().replace(/[^0-9.]/g, ""));
      } else {
        this.vm = newValue;
      }
    }
  },
  mounted() {
    if (this.isCurrency) {
      this.vm = this.modelValue ? Number(this.modelValue.toString().replace(/[^0-9.]/g, "")) : null;
    } else {
      this.vm = this.modelValue;
    }
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      if (this.onBlurValidation) {
        this.validateRules(this.vm);
      }
    },
    onFocus() {
      this.$emit("focus");
      this.errors = [];
    },
    validate(modelValue: any) {
      return this.validateRules(modelValue);
    }
  }
});
</script>

<style scoped></style>
