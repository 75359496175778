
import { defineComponent } from "vue";

import lottie, { AnimationConfigWithPath } from "lottie-web";

enum AnimationType {
  SVG = "svg",
  Canvas = "canvas",
  HTML = "html"
}

export default defineComponent({
  name: "DesignerAnimation",
  props: {
    animationType: {
      type: String as () => "svg" | "canvas" | "html",
      default: "svg",
      validator: (value: AnimationType) =>
        Object.values(AnimationType).includes(value)
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    animationPath(): string {
      return `${process.env.BASE_URL}designer-animations/${this.name}.json`;
    }
  },
  mounted() {
    const animationContainer = this.$refs.animationContainer as HTMLDivElement;

    const animationConfig: AnimationConfigWithPath = {
      renderer: this.animationType,
      container: animationContainer,
      path: this.animationPath,
      loop: this.loop,
      name: this.name
    };

    lottie.loadAnimation(animationConfig);
  },
  beforeUnmount() {
    lottie.destroy(this.name);
  }
});
