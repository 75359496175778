
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
});
