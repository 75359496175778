
import { defineComponent, reactive, watch } from "vue";
import { ModelValue } from "@/types/utils";

import VDropdownList from "@/components/base/VDropdownList.vue";

import useInputValidation from "@/composables/utils/useInputValidation";

import { DropDownItem, State } from "./index";

export default defineComponent({
  name: "VDropdownSelect",
  props: {
    label: {
      type: String,
      default: "Label",
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: "",
      required: false
    },
    items: {
      type: Array as () => DropDownItem[],
      default: () => []
    },
    rules: {
      type: Array as () => ((modelValue: ModelValue) => string | boolean)[],
      required: false,
      default: () => []
    },
    onBlurValidation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    VDropdownList
  },
  data() {
    return {
      isExpanded: false,
      defaultText: "Select an Item"
    };
  },
  setup(props, context) {
    const { isValid, errors, validateRules } = useInputValidation(props.rules);

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        validateRules(newValue);
      }
    );

    return {
      isValid,
      errors,
      validateRules
    };
  },
  computed: {
    selectedText(): any {
      if (this.modelValue) {
        const foundValue = this.items.find(item => {
          return item.value === this.modelValue;
        });

        return foundValue?.text;
      } else {
        return "";
      }
    }
  },
  methods: {
    onItemSelection(item: any) {
      this.$emit("update:modelValue", item.value);
      this.isExpanded = false;
    }
  }
});
