
import { defineComponent, ref, watch } from "vue";

import TechnicalFaultDialog from "@/components/shared/TechnicalFaultDialog.vue";
import { scrollToTop, validateEmail, validateName } from "@/util/helpers";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting.ts";
import { FormRef } from "@/types/base/";

export default defineComponent({
  components: {
    TechnicalFaultDialog
  },
  setup() {
    const { quotation, submitQuote, isSubmitSuccess, isError, isSubmitting } = useBusinessQuoting();
    const isSubmitDialogOpen = ref(false);
    const isErrorDialogOpen = ref(false);

    const specialCharacterRule = (v: any) => {
      if (!v) {
        return true;
      } else {
        return (
          /^[a-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžßÇ \t.,_@?!/.:;'"&$%*()+=\n\r\-–]+$/i.test(
            v
          ) || "Please enter a valid character"
        );
      }
    };

    const form = ref<null | FormRef>(null);
    const consentError = ref("");

    watch(
      () => quotation.referrerConsent,
      () => {
        consentError.value = "";
      }
    );

    watch([isSubmitSuccess, isError], ([isSuccess, isError]) => {
      console.log({ isSuccess, isError });
      if (isSuccess) {
        isSubmitDialogOpen.value = true;
      }

      if (isError) {
        isErrorDialogOpen.value = true;
      }
    });

    const onSubmit = async () => {
      if (form.value?.validate()) {
        if (quotation.referrerConsent) {
          consentError.value = "";
          await submitQuote();
        } else {
          consentError.value = "Please select the checkbox";
        }
      }
    };

    return {
      quotation,
      isSubmitDialogOpen,
      isSubmitSuccess,
      isErrorDialogOpen,
      validateName,
      validateEmail,
      form,
      onSubmit,
      consentError,
      isSubmitting,
      specialCharacterRule
    };
  },
  created() {
    scrollToTop();
  }
});
