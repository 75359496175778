<template>
  <v-form ref="form" autocomplete="off" class="pt-4 pb-10">
    <currency-input
      :rules="[
        v => !!v || 'Purchase price is required',
        v => v >= 5000 || 'Our minimum purchase price is $5,000',
        v => v <= 1000000 || 'Our maximum purchase price is $1,000,000'
      ]"
      data-testid="purchase-price"
      inputmode="decimal"
      prefix
      prefix-icon="dollar"
      placeholder="0"
      v-model="quotation.purchasePrice"
    >
      <div class="mb-2">Purchase price (GST inclusive)</div>
    </currency-input>
    <currency-input
      autocomplete="off"
      prefix
      inputmode="decimal"
      prefix-icon="dollar"
      placeholder="0"
      data-testid="accessories-cost"
      :rules="[
        v => {
          if (!v) {
            return true;
          } else {
            return v <= 1000000 || 'Our maximum price is $1,000,000';
          }
        }
      ]"
      v-model="quotation.accessoriesCost"
    >
      <div class="mb-2">Total cost of accessories (optional)</div>
    </currency-input>

    <currency-input
      autocomplete="off"
      prefix
      inputmode="decimal"
      prefix-icon="dollar"
      v-model="quotation.depositAmt"
      data-testid="deposit-amount"
      placeholder="0"
    >
      <div class="mb-2">
        Deposit/trade in amount (optional)
        <tool-tip
          class="inline-block"
          :text="
            'Please input the total amount the customer is contributing to the purchase of the asset.'
          "
          >i</tool-tip
        >
      </div>
    </currency-input>

    <div class="sm:w-96">
      <v-button-group-slider
        label="Frequency of repayments"
        data-testid="repayment-frequency"
        v-model="quotation.frequency"
        :items="items"
      ></v-button-group-slider>
    </div>
    <div>
      <v-dropdown-select
        data-testid="loan-term"
        v-model.number="loanTermSelected"
        :items="loanTermList"
        label="Loan term"
      ></v-dropdown-select>
    </div>
    <v-input
      autocomplete="off"
      data-testid="interest-rate"
      v-model.number="quotation.interestRate"
      label="Interest rate % (p.a.)"
      inputmode="decimal"
      :rules="[
        v => !!v || 'Interest rate is required',
        v => v <= 8 || 'Minimum interest rate is 8%'
      ]"
    ></v-input>
    <div>
      <v-radio-group
        data-testid="gst-radio"
        v-model="quotation.isIncludeGST"
        label="Would you like to pay back GST?"
        :items="gstRadioItems"
      ></v-radio-group>
      <p v-if="includeGSTError" class="text-error">{{ includeGSTError }}</p>
    </div>
    <v-card class="w-full px-4 py-6 mx-auto mt-8 rounded-2xl sm:w-96">
      <div>
        <h4 class="text-2xl font-bold text-center">
          <span v-if="!isCalculating">{{
            quotation.repaymentAmount ? formatMoney(quotation.repaymentAmount) : ""
          }}</span>
          <span>
            <designer-animation
              v-if="isCalculating"
              class="w-5 h-5"
              name="spinner"
            ></designer-animation>
          </span>
          per
          {{ frequencySelectedText }} {{ isIncludeGST ? "**" : "" }}
        </h4>
      </div>
      <div class="flex justify-between mt-4 sm:px-8">
        <div class="space-y-0.5">
          <p>Term:</p>
          <p>Interest rate:</p>
          <p>Total loan:</p>
          <p>Total interest:</p>
        </div>
        <div class="space-y-0.5">
          <p>{{ quotation.loanTerm }} {{ frequencySelectedText }}s</p>
          <p>{{ quotation.interestRate }}% p.a. *</p>
          <p>{{ loanAmount ? formatMoney(loanAmount) : "" }}</p>
          <p>
            {{ quotation.totalInterest ? formatMoney(quotation.totalInterest) : "" }}
          </p>
        </div>
      </div>
      <div class="mt-8">
        <p v-if="isIncludeGST" class="mb-4">
          **The above repayment is based on the GST payment of
          <strong>{{ formatMoney(gstAmount) }}</strong>
          being returned in month three.
        </p>
        <p>
          *The repayments have been calculated at the estimated interest rate above. However, if the
          application is successful, your interest rate may vary depending on your circumstances.
        </p>
      </div>
    </v-card>
    <div class="flex flex-col items-center mt-8 sm:flex-row-reverse sm:justify-around">
      <v-button data-testid="next-btn" @click.prevent="goToNextStep" class="w-64"
        >Next: Customer details</v-button
      >
      <v-button
        data-testid="back-btn"
        type="secondary"
        @click.prevent="$router.go(-1)"
        class="w-64 mt-4 sm:mt-0"
        >Back</v-button
      >
    </div>
    <v-dialog v-model="isErrorDialogOpen">
      <technical-fault-dialog @close="isErrorDialogOpen = false" />
    </v-dialog>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";

import { QuotingFormStep } from "@/composables/quoting/";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";
import useLoanTermCalculation from "@/composables/quoting/useLoanTermCalculation";
import useCalculateRepayment from "@/composables/quoting/useCalculateRepayment";

import ToolTip from "@/components/shared/ToolTip.vue";
import TechnicalFaultDialog from "@/components/shared/TechnicalFaultDialog.vue";

import { FormRef } from "@/types/base/";

import { capitalizeFirstLetter, scrollToTop, formatMoney } from "@/util/helpers";

import { IncludeGST } from "@/composables/quoting/";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CalculatorStep",
  components: {
    ToolTip,
    TechnicalFaultDialog
  },
  setup() {
    // Compositions
    const {
      quotation,
      loanTermSelected,
      loanAmount,
      gstAmount,
      isDepAmountLimit,
      isLoanAmountInLimit
    } = useBusinessQuoting();

    useLoanTermCalculation();

    const {
      isCalculating,
      frequencySelectedText,
      isLoanAmountInLimits,
      isErrorRepayment
    } = useCalculateRepayment();

    const router = useRouter();
    const form = ref<null | FormRef>(null);

    // Errors
    const includeGSTError = ref("");
    const depAmountError = ref("");

    const isErrorDialogOpen = ref(false);
    watch(
      () => quotation.isIncludeGST,
      newValue => {
        if (newValue) {
          includeGSTError.value = "";
        }
      }
    );

    watch(isDepAmountLimit, newValue => {
      if (newValue) {
        depAmountError.value = "Deposit amount should not be greater than total price.";
      }
    });

    watch(isLoanAmountInLimit, newValue => {
      if (newValue) {
        depAmountError.value = "Deposit amount should not be greater than total price.";
      }
    });

    watch(isErrorRepayment, (newValue, oldValue) => {
      if (newValue) {
        isErrorDialogOpen.value = true;
      }
    });

    const isIncludeGST = computed(() => (quotation.isIncludeGST === IncludeGST.Yes ? true : false));

    const goToNextStep = async () => {
      if (form.value?.validate()) {
        if (quotation.isIncludeGST) {
          router.push(`/create-quote/${QuotingFormStep.CustomerDetails}`);
        } else {
          includeGSTError.value = "Please choose an option";
        }
      }
    };

    return {
      quotation,
      loanTermSelected,
      loanAmount,
      isIncludeGST,
      goToNextStep,
      gstAmount,
      frequencySelectedText,
      capitalizeFirstLetter,
      isCalculating,
      form,
      formatMoney,
      isLoanAmountInLimits,
      includeGSTError,
      depAmountError,
      isLoanAmountInLimit,
      isErrorDialogOpen,
      isErrorRepayment
    };
  },
  data() {
    return {
      gstRadioItems: [
        {
          text: "Yes",
          value: IncludeGST.Yes
        },
        {
          text: "No",
          value: IncludeGST.No
        }
      ],
      items: [
        {
          id: "Weekly",
          name: "Weekly",
          value: "W"
        },
        {
          id: "fortnightly",
          name: "Fortnightly",
          value: "B"
        },
        {
          id: "Monthly",
          name: "Monthly",
          value: "M"
        }
      ],
      loanTermList: [
        {
          text: "6 months",
          value: 0.5
        },
        {
          text: "1 year",
          value: 1
        },
        {
          text: "2 years",
          value: 2
        },
        {
          text: "3 years",
          value: 3
        },
        {
          text: "4 years",
          value: 4
        },
        {
          text: "5 years",
          value: 5
        },
        {
          text: "6 years",
          value: 6
        },
        {
          text: "7 years",
          value: 7
        }
      ]
    };
  },
  mounted() {
    scrollToTop();
  }
});
</script>

<style></style>
