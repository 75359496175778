<template>
  <div class="flex items-center justify-center h-full px-4 py-6 md:py-auto">
    <div>
      <p class="font-bold text-center sm:text-xl">Technical Fault</p>
      <p class="mt-4 text-center">
        Sorry, we are not able to complete your application. For further
        assistance, please call us on
        <strong><a href="tel:0800 88 40 40">0800 88 40 40</a></strong> (Mon-Fri
        8.00am - 5.30pm).
      </p>
      <v-button class="block px-8 mx-auto sm:w-32 mt-14" @click="$emit('close')"
        >Ok</v-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TechnicalFaultDialog"
});
</script>

<style></style>
