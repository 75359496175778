<template>
  <div class="flex flex-col items-center px-4 py-6 mt-12">
    <p>
      Heartland Bank’s lending criteria, terms and conditions and fees apply.
    </p>
    <p class="mt-2 mr-auto sm:mr-0">
      © Copyright {{ currentYear }} Heartland Bank Limited
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
});
</script>

<style></style>
