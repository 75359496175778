
import { defineComponent, onBeforeUnmount } from "vue";

import ToolBar from "@/components/shared/ToolBar.vue";
import CalculatorStep from "@/components/quoting/steps/CalculatorStep.vue";
import CustomerDetailsStep from "@/components/quoting/steps/CustomerDetailsStep.vue";
import ReferrerDetailsStep from "@/components/quoting/steps/ReferrerDetailsStep.vue";

import { QuotingFormStep, QuotingFormStepDetails } from "@/composables/quoting";

import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";

interface CreateQuotePageData {
  steps: QuotingFormStepDetails[];
}

export default defineComponent({
  name: "CreateQuote",
  components: {
    ToolBar,
    CalculatorStep,
    CustomerDetailsStep,
    ReferrerDetailsStep
  },
  setup() {
    const { resetDefaults } = useBusinessQuoting();
    onBeforeUnmount(() => {
      resetDefaults();
    });
  },
  computed: {
    id(): string | string[] {
      return this.$route.params.id;
    },
    stepDetails(): QuotingFormStepDetails {
      const foundStep = this.steps.find(step => {
        return step.path === this.id;
      });
      return foundStep ? foundStep : this.steps[0];
    },
    currentStep(): number {
      const foundIndex = this.steps.findIndex(step => {
        return step.path === this.id;
      });
      return foundIndex >= 0 ? foundIndex + 1 : 0;
    }
  },
  data(): CreateQuotePageData {
    return {
      steps: [
        {
          title: "Quote details",
          path: QuotingFormStep.Calculator,
          component: "calculator-step"
        },
        {
          title: "Customer details",
          path: QuotingFormStep.CustomerDetails,
          component: "customer-details-step"
        },
        {
          title: "Referrer details",
          path: QuotingFormStep.ReferrerDetails,
          component: "referrer-details-step"
        }
      ]
    };
  }
});
