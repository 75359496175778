import { watch, onMounted } from "vue";

import { calculateLoanTerm } from "@/util/shared/index";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";

const useLoanTermCalculation = () => {
  const { quotation, loanTermSelected } = useBusinessQuoting();

  // Methods
  const assignLoanTerm = (loanTerm: number, frequency: string) => {
    quotation.loanTerm = calculateLoanTerm(loanTerm, frequency);
  };

  onMounted(() => {
    assignLoanTerm(loanTermSelected.value || 0, quotation.frequency);
  });

  // Watchers
  watch(loanTermSelected, newTermSelected => {
    if (newTermSelected) {
      assignLoanTerm(newTermSelected, quotation.frequency);
    }
  });

  watch(
    () => quotation.frequency,
    newFrequency => {
      if (newFrequency) {
        assignLoanTerm(loanTermSelected.value || 0, newFrequency);
      }
    }
  );
};

export default useLoanTermCalculation;
