<template>
  <v-input :isCurrency="true" v-model="localValue" :rules="rules">
    <template v-slot:label>
      <slot></slot>
    </template>
  </v-input>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";

import { formatAsCurrency } from "@/util/helpers";

export default defineComponent({
  name: "CurrencyInput",
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    // const formatAsCurrency = (value: number) => {
    //   const formatter = new Intl.NumberFormat("en-NZ", {
    //     style: "decimal",
    //     currency: "NZD",
    //     maximumFractionDigits: 2,
    //   });

    //   console.log("formatting, ", value);
    //   console.log("formatted", formatter.format(value));
    //   return formatter.format(value);
    // };

    const localValue = computed({
      get: () => {
        return props.modelValue ? formatAsCurrency(props.modelValue) : null;
      },
      set: value => {
        return context.emit(
          "update:modelValue",
          Number(value?.toString().replace(/[^0-9.]/g, ""))
        );
      }
    });

    return {
      localValue
    };
  }
});
</script>

<style></style>
