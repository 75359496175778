<template>
  <div class="loader-container">
    <div class="loader-bar" :class="{ loading: isLoading }"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped>
.loader-container {
  display: block;
  position: relative;
  height: 2px;
  overflow: hidden;
}

.loader-container .loader-bar {
  position: absolute;
  top: 0px;
  left: -60%;
  height: 100%;
  width: 40%;
  background-color: var(--primary-1);
}

.loader-container .loader-bar.loading {
  animation: animate-linear-loader 1.5s linear infinite;
}

@keyframes animate-linear-loader {
  0% {
    left: -60%;
  }
  100% {
    left: 110%;
  }
}
</style>
