export function scrollToTop(): void {
  console.log("scrolling to top");
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export const formatAsCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-NZ", {
    style: "decimal",
    currency: "NZD",
    maximumFractionDigits: 2
  });
  return formatter.format(value);
};

export function scrollToToolBar(): void {
  console.log("scrolling to top");
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function validateEmail(email: string): boolean {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; //eslint-disable-line
  if (!reg.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const validateName = (name: string): boolean => {
  const reg = /^(?!\d+$)(?:[a-zA-Z][a-zA-Z '-]*)?$/;
  if (!reg.test(name)) {
    return false;
  } else {
    return true;
  }
};

export const formatDecimal = (x: number | string): string => {
  return Number.parseFloat(x.toString()).toFixed(2);
};

export function formatMoney(inputString: number | string) {
  const value = parseFloat(inputString.toString());

  if (isNaN(value)) return "$0.00";

  const formatter = new Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    minimumFractionDigits: 2
  });

  return formatter.format(value);
}
