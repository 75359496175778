import { ref } from "vue";

const useInputValidation = (
  rules: ((modelValue: any) => string | boolean)[]
) => {
  const isValid = ref<boolean>(true);
  const errors = ref<string[]>([]);

  function validateRules(modelValue: any) {
    errors.value = [];
    for (let index = 0; index < rules.length; index++) {
      if (errors.value.length < 1) {
        const rule = rules[index];

        const valid = typeof rule === "function" ? rule(modelValue) : rule;

        if (valid === false || typeof valid === "string") {
          errors.value.push(valid || "");
        } else if (typeof valid !== "boolean") {
          console.error(
            `Rules should return a string or boolean, received '${typeof valid}' instead`,
            rules
          );
        }
      }
    }

    isValid.value = errors.value.length === 0 ? true : false;
    return isValid.value;
  }

  return {
    isValid,
    errors,
    validateRules
  };
};

export default useInputValidation;
