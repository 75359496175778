<template>
  <ToolBar class="mb-20 md:px-10">
    <template v-slot:default>
      <div class="flex items-center justify-between h-full">
        <v-icon
          @click="$router.go(-1)"
          class="inline-block cursor-pointer h-7 w-7 md:w-9 md:h-9"
          name="chevron-left"
        ></v-icon>
        <h1 class="inline-block text-xl font-bold md:text-2xl">
          {{ stepDetails.title }}
        </h1>
        <p class="inline-block font-bold text-md md:font-bold md:text-xl">
          {{ currentStep }} of {{ steps.length }}
        </p>
      </div>
    </template>
  </ToolBar>
  <main class="pt-20">
    <div class="container max-w-screen-sm px-4 mx-auto md:px-0">
      <component :is="stepDetails.component"> </component>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from "vue";

import ToolBar from "@/components/shared/ToolBar.vue";
import CalculatorStep from "@/components/quoting/steps/CalculatorStep.vue";
import CustomerDetailsStep from "@/components/quoting/steps/CustomerDetailsStep.vue";
import ReferrerDetailsStep from "@/components/quoting/steps/ReferrerDetailsStep.vue";

import { QuotingFormStep, QuotingFormStepDetails } from "@/composables/quoting";

import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";

interface CreateQuotePageData {
  steps: QuotingFormStepDetails[];
}

export default defineComponent({
  name: "CreateQuote",
  components: {
    ToolBar,
    CalculatorStep,
    CustomerDetailsStep,
    ReferrerDetailsStep
  },
  setup() {
    const { resetDefaults } = useBusinessQuoting();
    onBeforeUnmount(() => {
      resetDefaults();
    });
  },
  computed: {
    id(): string | string[] {
      return this.$route.params.id;
    },
    stepDetails(): QuotingFormStepDetails {
      const foundStep = this.steps.find(step => {
        return step.path === this.id;
      });
      return foundStep ? foundStep : this.steps[0];
    },
    currentStep(): number {
      const foundIndex = this.steps.findIndex(step => {
        return step.path === this.id;
      });
      return foundIndex >= 0 ? foundIndex + 1 : 0;
    }
  },
  data(): CreateQuotePageData {
    return {
      steps: [
        {
          title: "Quote details",
          path: QuotingFormStep.Calculator,
          component: "calculator-step"
        },
        {
          title: "Customer details",
          path: QuotingFormStep.CustomerDetails,
          component: "customer-details-step"
        },
        {
          title: "Referrer details",
          path: QuotingFormStep.ReferrerDetails,
          component: "referrer-details-step"
        }
      ]
    };
  }
});
</script>

<style></style>
