<template>
  <div class="flex cursor-pointer">
    <div
      class="relative flex-none inline-block w-6 h-6 mt-1 rounded-md shadow-input hover:shadow-input_hover"
    >
      <input
        :id="$attrs.name"
        class="absolute inset-0 w-6 h-6 bg-white rounded-md appearance-none min-w-max focus:outline-none"
        :class="{ 'bg-primary1': modelValue }"
        type="checkbox"
        :checked="modelValue"
        v-bind="$attrs"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click.prevent="$emit('update:modelValue', !modelValue)"
        fill="none"
        v-show="modelValue"
        class="absolute w-5 h-5 pt-1 pl-1 text-white"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
    </div>
    <label v-if="label" :for="$attrs.name" class="ml-2 cursor-pointer">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VCheckbox",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    },
    label: {
      type: String,
      default: "Label"
    }
  }
});
</script>

<style></style>
