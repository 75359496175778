<template>
  <ToolBar class="mb-20 md:px-10">
    <template v-slot:default>
      <div class="flex items-center justify-between h-full">
        <v-icon
          @click="$router.go(-1)"
          class="inline-block cursor-pointer h-7 w-7 md:w-9 md:h-9"
          name="chevron-left"
        ></v-icon>
        <h1 class="inline-block text-xl font-bold md:text-2xl">Contact us</h1>
        <v-icon
          @click="$router.push('/')"
          class="inline-block cursor-pointer h-7 w-7 md:w-9 md:h-9"
          name="close"
        ></v-icon>
      </div>
    </template>
  </ToolBar>
  <main class="pt-20">
    <div class="container max-w-screen-sm px-4 mx-auto md:px-0">
      <h1 class="mt-12 text-xl font-bold md:text-2xl">Get in touch</h1>
      <p class="mt-4">
        Call us toll free on
        <a class="underline" href="tel:0800 88 40 40"> 0800 88 40 40</a> Mon -
        Fri between 8.30am - 5.00pm.
      </p>
      <p class="mt-4">
        Or send us an email at <br />
        <a
          class="underline break-all text-primary1"
          href="mailto:businessapplications@heartland.co.nz"
          >businessapplications@heartland.co.nz</a
        >
      </p>
      <p class="mt-4">
        We'd love to hear your feedback, including any modifications to the
        quoting tool you'd like to see in the future. Please send your
        recommendations to the above email address.
      </p>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ToolBar from "@/components/shared/ToolBar.vue";

export default defineComponent({
  name: "ContactUs",
  components: {
    ToolBar
  }
});
</script>

<style></style>
