import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/pages/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "empty",
      title: "Home"
    }
  },
  {
    path: "/create-quote/:id",
    name: "CreateQuote",
    meta: {
      layout: "empty",
      title: "Create quote"
    },
    component: () => import("@/pages/CreateQuote.vue")
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    meta: {
      layout: "empty",
      title: "Contact Us"
    },
    component: () => import("@/pages/ContactUs.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // Set title
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " | Heartland Partners";
  } else {
    document.title = "Heartland Partners";
  }
  next();
});

export default router;
