import { reactive, ref, watch, onMounted, computed } from "vue";
import axios, { CancelTokenSource } from "axios";
import useApi from "@/composables/api/useApi";
import {
  RepaymentRequest,
  QuoteRepayment,
  CalculatorRepaymentFrequency,
  RepaymentFrequencyText,
  IncludeGST
} from ".";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";

const useCalculateRepayment = () => {
  const { api } = useApi();
  const { loanAmount, quotation, loanTermSelected } = useBusinessQuoting();

  const repayment: QuoteRepayment = reactive({
    repaymentAmount: 0,
    totalCost: 0,
    totalInterest: 0
  });

  const LOAN_AMOUNT_LOW_LIMIT = 5000;
  const LOAN_AMOUNT_HIGH_LIMIT = 1000000;
  const LOAN_AMOUNT_GST_LIMIT = 100000;

  const isLoanAmountInLimits = computed(() => {
    return loanAmount.value >= LOAN_AMOUNT_LOW_LIMIT && loanAmount.value <= LOAN_AMOUNT_HIGH_LIMIT;
  });

  const isCalculating = ref(false);
  const isErrorRepayment = ref(false);
  let cancelToken: CancelTokenSource;

  const getRepayment = async (payload: RepaymentRequest): Promise<any> => {
    isCalculating.value = true;
    isErrorRepayment.value = false;

    //Check if there are any previous pending requests
    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    try {
      const response = await api.post("commloan/v1/calculate", payload, {
        cancelToken: cancelToken.token
      });

      const { repaymentAmount, totalCost, totalInterest } = response.data.data;

      quotation.totalCost = parseFloat(totalCost);
      quotation.repaymentAmount = parseFloat(repaymentAmount);
      quotation.totalInterest = parseFloat(totalInterest);
    } catch (err) {
      if (axios.isCancel(err)) {
        // console.log("Previous request canceled, new request is send", err.message);
      } else {
        // handle error
        isErrorRepayment.value = true;
      }
    }
    isCalculating.value = false;
  };

  const calculateRepayment = () => {
    if (
      isLoanAmountInLimits.value &&
      quotation.interestRate &&
      quotation.loanTerm &&
      quotation.frequency
    ) {
      getRepayment({
        loanAmt: loanAmount.value,
        frequency: quotation.frequency,
        loanTerm: quotation.loanTerm,
        interestRate: quotation.interestRate || 0,
        isIncludeGST: quotation.isIncludeGST || IncludeGST.No,
        purchasePrice: quotation.purchasePrice || 0
      });
    }
  };

  onMounted(() => {
    calculateRepayment();
  });

  const frequencySelectedText = computed(() => {
    if (quotation.frequency === CalculatorRepaymentFrequency.Weekly) {
      return RepaymentFrequencyText.Week;
    } else if (quotation.frequency === CalculatorRepaymentFrequency.Fortnightly) {
      return RepaymentFrequencyText.Fortnight;
    } else if (quotation.frequency === CalculatorRepaymentFrequency.Monthly) {
      return RepaymentFrequencyText.Month;
    } else {
      return "";
    }
  });

  // Watchers
  watch(isCalculating, (newValue, oldValue) => {
    if (newValue === oldValue) return;
    if (newValue) {
      repayment.totalInterest = 0;
      repayment.repaymentAmount = 0;
    }
  });

  watch(loanAmount, (newLoanAmount, oldLoanAmount) => {
    if (newLoanAmount === oldLoanAmount) return;
    calculateRepayment();

    if (newLoanAmount >= LOAN_AMOUNT_GST_LIMIT) {
      quotation.isIncludeGST = IncludeGST.Yes;
    } else if (newLoanAmount < LOAN_AMOUNT_GST_LIMIT) {
      quotation.isIncludeGST = null;
    }
  });

  watch(
    () => quotation.frequency,
    (newFrequency, oldFrequency) => {
      if (newFrequency === oldFrequency) return;
      if (newFrequency) {
        calculateRepayment();
      }
    }
  );

  watch(
    () => quotation.interestRate,
    newInterestRate => {
      if (newInterestRate) {
        calculateRepayment();
      }
    }
  );

  watch(loanTermSelected, (newloanTerm, oldLoanTerm) => {
    if (newloanTerm === oldLoanTerm) return;
    if (newloanTerm) {
      calculateRepayment();
    }
  });

  watch(
    () => quotation.isIncludeGST,
    (newValue, oldValue) => {
      if (newValue === oldValue) return;
      calculateRepayment();
    }
  );

  watch(isLoanAmountInLimits, (newValue, oldValue) => {
    console.log({ newValue, oldValue });
    if (newValue === oldValue) return;
    if (!newValue) {
      quotation.repaymentAmount = 0;
      quotation.totalInterest = 0;
    }
  });

  return {
    repayment,
    isCalculating,
    isErrorRepayment,
    frequencySelectedText,
    isLoanAmountInLimits
  };
};

export default useCalculateRepayment;
