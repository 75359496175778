<template>
  <div
    data-testid="v-card"
    class="transition-shadow duration-300 cursor-pointer shadow-card hover:shadow-card_hover"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VCard"
});
</script>

<style scoped>
div {
  -webkit-tap-highlight-color: transparent;
}
</style>
