import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "@/assets/css/tailwind.css";

import "@/registerServiceWorker";

// Layouts
import Default from "@/layouts/Default.vue";
import Empty from "@/layouts/Empty.vue";

// Base components
import VIcon from "@/components/base/VIcon.vue";
import VCard from "@/components/base/VCard.vue";
import VInput from "@/components/base/VInput.vue";
import VButton from "@/components/base/VButton.vue";
import VDropdownSelect from "@/components/base/VDropdownSelect/VDropdownSelect.vue";
import VButtonGroupSlider from "@/components/base/VButtonGroupSlider.vue";
import VForm from "@/components/base/VForm.vue";
import VCheckbox from "@/components/base/VCheckbox.vue";
import VRadio from "@/components/base/VRadio.vue";
import VRadioGroup from "@/components/base/VRadioGroup.vue";
import VTextarea from "@/components/base/VTextarea.vue";
import VDialog from "@/components/base/VDialog.vue";
import VAutocompleteInput from "@/components/base/VAutocompleteInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

// Shared components
import DesignerAnimation from "@/components/shared/DesignerAnimation.vue";

/* Create App instance */
const app = createApp(App);

/* Global components registration */
const components = {
  VIcon,
  VCard,
  VInput,
  VButton,
  VDropdownSelect,
  VButtonGroupSlider,
  VForm,
  VCheckbox,
  VRadio,
  VRadioGroup,
  VTextarea,
  VDialog,
  VAutocompleteInput,
  DesignerAnimation,
  CurrencyInput
};

// Register each component
Object.entries(components).forEach(([name, component]) => {
  app.component(name, component);
});

/* Layouts registration */
app.component("default-layout", Default);
app.component("empty-layout", Empty);

app.directive("currency", {
  mounted(el: any, binding: any, vnode: any) {
    console.log("the directive", el, binding, vnode);
  }
});

// Mounting the app
app.use(router).mount("#app");
