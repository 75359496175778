
import { defineComponent } from "vue";

export default defineComponent({
  name: "VCheckbox",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    },
    label: {
      type: String,
      default: "Label"
    }
  }
});
