<template>
  <div class="my-4">
    <label :for="$attrs.name" v-if="label" class="block mb-2">{{ label }}</label>
    <div class="h-full">
      <button
        ref="button"
        class="relative inline-flex items-center w-full px-4 mb-1 rounded-md h-11 focus:outline-none shadow-input hover:shadow-input_hover focus:ring-1 focus:ring-primary1"
        :class="{ invalid: errors.length }"
        type="button"
        @click="isExpanded = !isExpanded"
      >
        <span class="inline-block mr-auto">
          <slot name="defaultText">
            {{ selectedText || defaultText }}
          </slot>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          class="absolute inline w-6 h-6 transition-transform duration-500 right-2 text-primary1"
          viewBox="0 0 24 24"
          stroke="currentColor"
          :class="isExpanded ? 'transform rotate-180' : ''"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <VDropdownList @selected="onItemSelection" :isActive="isExpanded" :items="items" />
    </div>
    <template :key="i" v-for="(error, i) in errors">
      <p class="text-error">{{ error }}</p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { ModelValue } from "@/types/utils";

import VDropdownList from "@/components/base/VDropdownList.vue";

import useInputValidation from "@/composables/utils/useInputValidation";

import { DropDownItem, State } from "./index";

export default defineComponent({
  name: "VDropdownSelect",
  props: {
    label: {
      type: String,
      default: "Label",
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: "",
      required: false
    },
    items: {
      type: Array as () => DropDownItem[],
      default: () => []
    },
    rules: {
      type: Array as () => ((modelValue: ModelValue) => string | boolean)[],
      required: false,
      default: () => []
    },
    onBlurValidation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    VDropdownList
  },
  data() {
    return {
      isExpanded: false,
      defaultText: "Select an Item"
    };
  },
  setup(props, context) {
    const { isValid, errors, validateRules } = useInputValidation(props.rules);

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        validateRules(newValue);
      }
    );

    return {
      isValid,
      errors,
      validateRules
    };
  },
  computed: {
    selectedText(): any {
      if (this.modelValue) {
        const foundValue = this.items.find(item => {
          return item.value === this.modelValue;
        });

        return foundValue?.text;
      } else {
        return "";
      }
    }
  },
  methods: {
    onItemSelection(item: any) {
      this.$emit("update:modelValue", item.value);
      this.isExpanded = false;
    }
  }
});
</script>

<style scoped>
div {
  -webkit-tap-highlight-color: transparent;
}
</style>
