
import { defineComponent } from "vue";
import ToolBar from "@/components/shared/ToolBar.vue";

interface Action {
  title: string;
  icon: string;
  path: string;
}

export default defineComponent({
  name: "Home",
  components: {
    ToolBar
  },
  data() {
    return {
      actions: [
        {
          title: "Complete a quote",
          icon: "document-edit",
          path: "/create-quote/calculator"
        },
        {
          title: "Contact us",
          icon: "message-bubble",
          path: "/contact-us"
        }
      ] as Action[]
    };
  },
  methods: {
    onCardClick(path: string) {
      this.$router.push(path);
    }
  }
});
