
import { defineComponent, ref, computed, watch } from "vue";

import { QuotingFormStep } from "@/composables/quoting/";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting";
import useLoanTermCalculation from "@/composables/quoting/useLoanTermCalculation";
import useCalculateRepayment from "@/composables/quoting/useCalculateRepayment";

import ToolTip from "@/components/shared/ToolTip.vue";
import TechnicalFaultDialog from "@/components/shared/TechnicalFaultDialog.vue";

import { FormRef } from "@/types/base/";

import { capitalizeFirstLetter, scrollToTop, formatMoney } from "@/util/helpers";

import { IncludeGST } from "@/composables/quoting/";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CalculatorStep",
  components: {
    ToolTip,
    TechnicalFaultDialog
  },
  setup() {
    // Compositions
    const {
      quotation,
      loanTermSelected,
      loanAmount,
      gstAmount,
      isDepAmountLimit,
      isLoanAmountInLimit
    } = useBusinessQuoting();

    useLoanTermCalculation();

    const {
      isCalculating,
      frequencySelectedText,
      isLoanAmountInLimits,
      isErrorRepayment
    } = useCalculateRepayment();

    const router = useRouter();
    const form = ref<null | FormRef>(null);

    // Errors
    const includeGSTError = ref("");
    const depAmountError = ref("");

    const isErrorDialogOpen = ref(false);
    watch(
      () => quotation.isIncludeGST,
      newValue => {
        if (newValue) {
          includeGSTError.value = "";
        }
      }
    );

    watch(isDepAmountLimit, newValue => {
      if (newValue) {
        depAmountError.value = "Deposit amount should not be greater than total price.";
      }
    });

    watch(isLoanAmountInLimit, newValue => {
      if (newValue) {
        depAmountError.value = "Deposit amount should not be greater than total price.";
      }
    });

    watch(isErrorRepayment, (newValue, oldValue) => {
      if (newValue) {
        isErrorDialogOpen.value = true;
      }
    });

    const isIncludeGST = computed(() => (quotation.isIncludeGST === IncludeGST.Yes ? true : false));

    const goToNextStep = async () => {
      if (form.value?.validate()) {
        if (quotation.isIncludeGST) {
          router.push(`/create-quote/${QuotingFormStep.CustomerDetails}`);
        } else {
          includeGSTError.value = "Please choose an option";
        }
      }
    };

    return {
      quotation,
      loanTermSelected,
      loanAmount,
      isIncludeGST,
      goToNextStep,
      gstAmount,
      frequencySelectedText,
      capitalizeFirstLetter,
      isCalculating,
      form,
      formatMoney,
      isLoanAmountInLimits,
      includeGSTError,
      depAmountError,
      isLoanAmountInLimit,
      isErrorDialogOpen,
      isErrorRepayment
    };
  },
  data() {
    return {
      gstRadioItems: [
        {
          text: "Yes",
          value: IncludeGST.Yes
        },
        {
          text: "No",
          value: IncludeGST.No
        }
      ],
      items: [
        {
          id: "Weekly",
          name: "Weekly",
          value: "W"
        },
        {
          id: "fortnightly",
          name: "Fortnightly",
          value: "B"
        },
        {
          id: "Monthly",
          name: "Monthly",
          value: "M"
        }
      ],
      loanTermList: [
        {
          text: "6 months",
          value: 0.5
        },
        {
          text: "1 year",
          value: 1
        },
        {
          text: "2 years",
          value: 2
        },
        {
          text: "3 years",
          value: 3
        },
        {
          text: "4 years",
          value: 4
        },
        {
          text: "5 years",
          value: 5
        },
        {
          text: "6 years",
          value: 6
        },
        {
          text: "7 years",
          value: 7
        }
      ]
    };
  },
  mounted() {
    scrollToTop();
  }
});
