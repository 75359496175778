
import { defineComponent, ref, watch } from "vue";

import { QuotingFormStep } from "@/composables/quoting/";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting.ts";

import { FormRef } from "@/types/base/";

import { scrollToTop, validateEmail, validateName } from "@/util/helpers";
import { useRouter } from "vue-router";

import PrivacyDeclaration from "@/components/shared/PrivacyDeclaration.vue";

export default defineComponent({
  name: "QuoteDetailsStep",
  components: {
    PrivacyDeclaration
  },
  setup() {
    const { quotation } = useBusinessQuoting();

    const form = ref<null | FormRef>(null);
    const privacyError = ref("");

    const specialCharacterRule = (v: any) => {
      if (!v) {
        return true;
      } else {
        return (
          /^[a-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžßÇ \t.,_@?!/.:;'"&$%*()+=\n\r\-–]+$/i.test(
            v
          ) || "Please enter a valid character"
        );
      }
    };

    watch(
      () => quotation.privacyDeclaration,
      () => {
        privacyError.value = "";
      }
    );

    const router = useRouter();

    const goToNextStep = async () => {
      if (form.value?.validate()) {
        if (quotation.privacyDeclaration) {
          privacyError.value = "";
          router.push(`/create-quote/${QuotingFormStep.ReferrerDetails}`);
        } else {
          privacyError.value = "Please select the checkbox";
        }
      }
    };

    return {
      quotation,
      goToNextStep,
      form,
      validateName,
      validateEmail,
      privacyError,
      specialCharacterRule
    };
  },
  methods: {
    validate() {
      this.validateName;
    }
  },
  created() {
    scrollToTop();
  }
});
