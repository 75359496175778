
import { defineComponent } from "vue";
const defaultLayout = "default";

export default defineComponent({
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    }
  }
});
