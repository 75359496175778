export interface RepaymentRequest {
  loanAmt: number;
  frequency: string;
  loanTerm: number;
  interestRate: number;
  isIncludeGST: IncludeGST;
  purchasePrice: number;
}

export interface QuoteRepayment {
  repaymentAmount: number;
  totalCost: number;
  totalInterest: number;
}

export enum QuotingFormStep {
  Calculator = "calculator",
  CustomerDetails = "customer-details",
  ReferrerDetails = "referrer-details"
}

export interface QuotingFormStepDetails {
  path: QuotingFormStep;
  title: string;
  component: string;
}

export interface Quotation {
  id?: string;
  purchasePrice: number | null;
  accessoriesCost: number | null;
  depositAmt: number | null;
  frequency: string;
  loanTerm: number;
  interestRate: number | null;
  custName: string;
  custContactNo: string;
  custEmail: string;
  prfContactTime: string;
  privacyDeclaration: boolean;
  optOut: boolean;
  referrerName: string;
  referrerContactNo: string;
  referrerEmail: string;
  comments: string;
  manufacturerName: string;
  referrerConsent: boolean;
  repaymentAmount: number;
  totalCost: number;
  totalInterest: number;
  gstAmt: number;
  isIncludeGST: IncludeGST | null;
}

export enum IncludeGST {
  Yes = "Y",
  No = "N"
}

export enum CalculatorRepaymentFrequency {
  Weekly = "W",
  Fortnightly = "B",
  Monthly = "M"
}

export enum RepaymentFrequencyText {
  Week = "week",
  Fortnight = "fortnight",
  Month = "month"
}

export interface Manufacturer {
  name: string;
  id: string;
}
