
import { defineComponent } from "vue";
export default defineComponent({
  name: "VRadioGroup",
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    label: {
      type: String,
      default: ""
    }
  }
});
