
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolTip",
  props: {
    text: {
      type: String,
      required: false,
      default: "Tooltip text placeholder"
    },
    direction: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      trigger: null as any,
      calculatedDirection: "bottom"
    };
  },
  mounted() {
    this.trigger = this.$refs.trigger;
    this.trigger.addEventListener("mouseenter", this.findTooltipDirection);
  },
  methods: {
    findTooltipDirection() {
      if (this.direction) {
        this.calculatedDirection = this.direction;
        return;
      }

      const { top, bottom } = this.trigger.getBoundingClientRect();
      const tooltipComputedStyle = window.getComputedStyle(
        this.trigger,
        "::after"
      );

      const tooltipHeight = parseInt(
        tooltipComputedStyle.getPropertyValue("height")
      );

      const extraSpace = 25; // takes into account the little margin between the tooltip box and the trigger

      const isTopLongEnough = top >= tooltipHeight + extraSpace;
      const isBottomLongEnough =
        window.innerHeight - bottom >= tooltipHeight + extraSpace;

      this.calculatedDirection = isTopLongEnough
        ? "top"
        : isBottomLongEnough
        ? "bottom"
        : "";
    }
  },
  beforeUnmount() {
    this.trigger.removeEventListener("mouseenter", this.findTooltipDirection);
  }
});
