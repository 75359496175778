<template>
  <div class="my-4">
    <label class="block mb-2">{{ label }}</label>
    <template :key="i" v-for="(item, i) in items">
      <v-radio
        class="mr-10"
        :modelValue="item.value"
        :selectedItem="modelValue"
        :label="item.text"
        @update:modelValue="$emit('update:modelValue', $event)"
      ></v-radio>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "VRadioGroup",
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    label: {
      type: String,
      default: ""
    }
  }
});
</script>

<style></style>
