<template>
  <teleport to="#modals">
    <div
      v-if="modelValue"
      class="fixed inset-0 w-screen h-screen transition-opacity duration-500 modal z-dialog"
    >
      <div
        v-bind="$attrs"
        class="z-50 transition-opacity duration-500 bg-white rounded-2xl w-72 sm:w-500px"
      >
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VDialog",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(46, 59, 65, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
