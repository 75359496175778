<template>
  <div class="flex flex-col justify-between h-screen">
    <ToolBar />
    <main class="pt-20">
      <slot></slot>
    </main>
    <Footer />
  </div>
</template>

<script>
import ToolBar from "@/components/shared/ToolBar";
import Footer from "@/components/shared/Footer.vue";

export default {
  name: "DefaultLayout",
  components: {
    ToolBar,
    Footer
  }
};
</script>

<style></style>
