<template>
  <div class="inline-flex">
    <div class="relative w-6 h-6 rounded-full">
      <input
        type="radio"
        :id="modelValue"
        class="w-full h-full border rounded-full appearance-none cursor-pointer hover:border-2 hover:border-primary1 focus:outline-none"
        :class="{
          ['bg-primary1 border-primary1']: isSelectedItem
        }"
        :name="name"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
      />
      <div
        v-show="isSelectedItem"
        class="absolute inset-0 w-3 h-3 bg-white rounded-full top-1/4 left-1/4"
      ></div>
    </div>
    <label :for="modelValue" class="ml-2 cursor-pointer">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VRadio",
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true
    },
    name: {
      type: String,
      default: "radio",
      required: true
    },
    selectedItem: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Label"
    }
  },
  computed: {
    isSelectedItem(): boolean {
      return this.selectedItem === this.modelValue;
    }
  }
});
</script>

<style></style>
