<template>
  <img v-bind="$attrs" :src="iconPath" :height="height" :width="width" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "50"
    },
    width: {
      type: String,
      default: "50"
    }
  },
  computed: {
    iconPath(): string {
      return require("@/assets/icons/" + this.name + ".svg");
    }
  },
  methods: {
    onClick() {
      console.log("image clicked");
    }
  }
});
</script>

<style></style>
