<template>
  <span
    class="tooltip"
    ref="trigger"
    :class="calculatedDirection"
    :data-tooltip="text"
  >
    <span class="tooltip-trigger">
      <slot></slot>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolTip",
  props: {
    text: {
      type: String,
      required: false,
      default: "Tooltip text placeholder"
    },
    direction: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      trigger: null as any,
      calculatedDirection: "bottom"
    };
  },
  mounted() {
    this.trigger = this.$refs.trigger;
    this.trigger.addEventListener("mouseenter", this.findTooltipDirection);
  },
  methods: {
    findTooltipDirection() {
      if (this.direction) {
        this.calculatedDirection = this.direction;
        return;
      }

      const { top, bottom } = this.trigger.getBoundingClientRect();
      const tooltipComputedStyle = window.getComputedStyle(
        this.trigger,
        "::after"
      );

      const tooltipHeight = parseInt(
        tooltipComputedStyle.getPropertyValue("height")
      );

      const extraSpace = 25; // takes into account the little margin between the tooltip box and the trigger

      const isTopLongEnough = top >= tooltipHeight + extraSpace;
      const isBottomLongEnough =
        window.innerHeight - bottom >= tooltipHeight + extraSpace;

      this.calculatedDirection = isTopLongEnough
        ? "top"
        : isBottomLongEnough
        ? "bottom"
        : "";
    }
  },
  beforeUnmount() {
    this.trigger.removeEventListener("mouseenter", this.findTooltipDirection);
  }
});
</script>

<style scoped>
.tooltip {
  position: relative;
  font-size: 16px;
  cursor: help;
  z-index: var(--zindex-tooltip);
}

.tooltip .tooltip-trigger {
  color: white;
  background-color: var(--primary-1);
  padding: 0 8px;
  border-radius: 50%;
}

.tooltip::before,
.tooltip::after {
  position: absolute;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease 0.3s;
}

.tooltip.top::before {
  content: "";
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: var(--primary-1) transparent transparent transparent;
  top: -20px;
  margin-left: -8px;
}

.tooltip.bottom::before {
  content: "";
  border-width: 0 8px 10px 8px;
  border-style: solid;
  border-color: transparent transparent var(--primary-1) transparent;
  bottom: -20px;
  margin-left: -8px;
}

.tooltip.left::before {
  content: "";
  border-width: 8px 0 8px 10px;
  border-style: solid;
  border-color: transparent transparent transparent var(--primary-1);
  left: -20px;
  margin-top: 8px;
}

.tooltip.right::before {
  content: "";
  border-width: 8px 10px 8px 0;
  border-style: solid;
  border-color: transparent var(--primary-1) transparent transparent;
  right: -20px;
  margin-left: 8px;
  margin-top: 6px;
}

.tooltip::after {
  content: attr(data-tooltip);
  background: var(--primary-1);
  font-size: 16px;
  width: 150px;
  border-radius: 10px;
  color: white;
  padding: 14px;
}

.tooltip.top::after {
  top: -20px;
  margin-left: -120px;
  transform: translateY(-100%);
}

.tooltip.bottom::after {
  top: 40px;
  left: -130px;
}

.tooltip.left::after {
  transform: translateX(-100%);
  left: -20px;
  bottom: -50px;
}

.tooltip.right::after {
  left: 35px;
  bottom: -50px;
}

/* Hover states */

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
}
</style>
