<template>
  <header class="fixed w-full h-20 px-4 z-toolbar bg-toolbar">
    <slot>
      <img class="w-40 h-20" src="@/assets/heartland-partners.svg" />
    </slot>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolBar"
});
</script>

<style scoped></style>
