<template>
  <v-form ref="form" class="pt-4 pb-10">
    <p>Please enter your details below.</p>
    <v-input
      v-model="quotation.referrerName"
      data-testid="referrer-name"
      :rules="[
        v => !!v || 'Name is required',
        specialCharacterRule,
        v => v.length <= 150 || 'Maximum length is 150'
      ]"
      label="Full name"
    ></v-input>
    <v-input
      type="tel"
      v-model="quotation.referrerContactNo"
      data-testid="referrer-contact-number"
      :rules="[
        v => {
          if (!v) {
            return true;
          } else {
            return /^\d+$/.test(v) || 'Please enter a valid contact number';
          }
        },
        v => {
          if (!v) {
            return true;
          } else {
            return v.length >= 9 || 'Please enter a valid contact number';
          }
        },
        v => {
          if (!v) {
            return true;
          } else {
            return v.length <= 11 || 'Please enter a valid contact number';
          }
        }
      ]"
      label="Contact number (optional)"
    ></v-input>
    <v-input
      type="email"
      data-testid="referrer-email"
      v-model="quotation.referrerEmail"
      :rules="[
        v => !!v || 'Email is required',
        v => validateEmail(v) || 'Please enter a valid email'
      ]"
      label="Email address"
    ></v-input>
    <v-textarea
      autocomplete="off"
      data-testid="referrer-comments"
      v-model="quotation.comments"
      label="Comments (optional)"
      :rules="[specialCharacterRule]"
    ></v-textarea>
    <div class="mt-6">
      <div class="p-4 bg-privacy_bg">
        <v-checkbox
          data-testid="customer-consent"
          v-model="quotation.referrerConsent"
          name="customer consent"
          label="I confirm that I have obtained the customer's consent to submit this referral on their behalf and for Heartland to contact the customer about this referral."
        >
        </v-checkbox>
      </div>
      <p class="text-error">
        {{ consentError }}
      </p>
    </div>

    <v-dialog data-testid="submit-dialog" v-model="isSubmitDialogOpen">
      <div class="flex items-center justify-center h-full px-10 py-4">
        <div>
          <p class="text-center sm:text-xl">
            Your referral has been successfully sent to the Heartland Business team.
          </p>
          <v-button class="block mx-auto mt-8 sm:w-32" @click="$router.push('/')">Done</v-button>
        </div>
      </div>
    </v-dialog>

    <v-dialog data-testid="fault-dialog" v-model="isErrorDialogOpen">
      <technical-fault-dialog @close="isErrorDialogOpen = false" />
    </v-dialog>
    <div class="flex flex-col items-center mt-8 sm:flex-row-reverse sm:justify-around">
      <v-button
        :is-loading="isSubmitting"
        :disabled="isSubmitting"
        @click.prevent="onSubmit"
        data-testid="submit-btn"
        class="w-64"
        >Submit quote</v-button
      >
      <v-button
        type="secondary"
        data-testid="back-btn"
        @click.prevent="$router.go(-1)"
        class="w-64 mt-4 sm:mt-0"
        >Back</v-button
      >
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import TechnicalFaultDialog from "@/components/shared/TechnicalFaultDialog.vue";
import { scrollToTop, validateEmail, validateName } from "@/util/helpers";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting.ts";
import { FormRef } from "@/types/base/";

export default defineComponent({
  components: {
    TechnicalFaultDialog
  },
  setup() {
    const { quotation, submitQuote, isSubmitSuccess, isError, isSubmitting } = useBusinessQuoting();
    const isSubmitDialogOpen = ref(false);
    const isErrorDialogOpen = ref(false);

    const specialCharacterRule = (v: any) => {
      if (!v) {
        return true;
      } else {
        return (
          /^[a-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžßÇ \t.,_@?!/.:;'"&$%*()+=\n\r\-–]+$/i.test(
            v
          ) || "Please enter a valid character"
        );
      }
    };

    const form = ref<null | FormRef>(null);
    const consentError = ref("");

    watch(
      () => quotation.referrerConsent,
      () => {
        consentError.value = "";
      }
    );

    watch([isSubmitSuccess, isError], ([isSuccess, isError]) => {
      console.log({ isSuccess, isError });
      if (isSuccess) {
        isSubmitDialogOpen.value = true;
      }

      if (isError) {
        isErrorDialogOpen.value = true;
      }
    });

    const onSubmit = async () => {
      if (form.value?.validate()) {
        if (quotation.referrerConsent) {
          consentError.value = "";
          await submitQuote();
        } else {
          consentError.value = "Please select the checkbox";
        }
      }
    };

    return {
      quotation,
      isSubmitDialogOpen,
      isSubmitSuccess,
      isErrorDialogOpen,
      validateName,
      validateEmail,
      form,
      onSubmit,
      consentError,
      isSubmitting,
      specialCharacterRule
    };
  },
  created() {
    scrollToTop();
  }
});
</script>

<style></style>
