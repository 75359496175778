
import { defineComponent } from "vue";

import ToolBar from "@/components/shared/ToolBar.vue";

export default defineComponent({
  name: "ContactUs",
  components: {
    ToolBar
  }
});
