<template>
  <ToolBar>
    <template v-slot:default>
      <div class="flex items-center justify-center h-full">
        <div class="flex w-full max-w-screen-sm">
          <img class="w-40 h-20" src="@/assets/heartland-partners.svg" />
        </div>
      </div>
    </template>
  </ToolBar>
  <main class="pt-20">
    <div class="container w-full max-w-screen-sm px-4 pt-16 mx-auto md:px-0">
      <h2 class="mb-4 text-xl font-bold text-primary1">Kia ora</h2>
      <h1 class="mb-8 text-lg">What would you like to do?</h1>
      <div class="grid grid-cols-2 gap-5 lg:gap-8 lg:grid-cols-3">
        <v-card
          class="flex flex-col items-center h-32 pb-4 rounded-md"
          v-for="(action, i) in actions"
          :key="i"
          width="150px"
          height="120px"
          @click="onCardClick(action.path)"
        >
          <v-icon class="my-auto text-center" :name="action.icon"></v-icon>
          <p class="font-medium text-primary1 text-md">
            {{ action.title }}
          </p>
        </v-card>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToolBar from "@/components/shared/ToolBar.vue";

interface Action {
  title: string;
  icon: string;
  path: string;
}

export default defineComponent({
  name: "Home",
  components: {
    ToolBar
  },
  data() {
    return {
      actions: [
        {
          title: "Complete a quote",
          icon: "document-edit",
          path: "/create-quote/calculator"
        },
        {
          title: "Contact us",
          icon: "message-bubble",
          path: "/contact-us"
        }
      ] as Action[]
    };
  },
  methods: {
    onCardClick(path: string) {
      this.$router.push(path);
    }
  }
});
</script>

<style scoped></style>
