<template>
  <div class="flex flex-col justify-between h-screen">
    <slot></slot>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Footer from "@/components/shared/Footer.vue";

export default defineComponent({
  components: {
    Footer
  },
  name: "EmptyLayout"
});
</script>

<style></style>
