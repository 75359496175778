<template>
  <div class="py-3">
    <label v-if="label" class="block mb-2" :for="$attrs.name">{{ label }}</label>
    <textarea
      @blur="onBlur"
      @focus="onFocus"
      v-bind="$attrs"
      :id="$attrs.name"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="w-full p-4 rounded-md appearance-none resize-none focus:outline-none shadow-input hover:shadow-input_hover focus:ring-1 focus:ring-primary1"
      rows="5"
    ></textarea>
    <template :key="i" v-for="(error, i) in errors">
      <p class="text-error">{{ error }}</p>
    </template>
  </div>
</template>

<script lang="ts">
import useInputValidation from "@/composables/utils/useInputValidation";
import { defineComponent, getCurrentInstance, inject, onBeforeUnmount, onMounted } from "vue";

export default defineComponent({
  name: "VTextarea",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "Label",
      required: false
    },
    modelValue: {
      type: String,
      default: ""
    },
    rules: {
      type: Array as () => ((modelValue: any) => string | boolean)[],
      required: false,
      default: () => []
    },
    onBlurValidation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      vm: null as any
    };
  },
  setup(props) {
    const { isValid, errors, validateRules } = useInputValidation(props.rules);

    onMounted(() => {
      const register = inject<any>("register");
      register(getCurrentInstance());
    });

    onBeforeUnmount(() => {
      const unregister = inject<any>("unregister");
      unregister(getCurrentInstance());
    });

    return {
      isValid,
      errors,
      validateRules
    };
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.vm = newValue;
    }
  },
  mounted() {
    this.vm = this.modelValue;
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      if (this.onBlurValidation) {
        this.validateRules(this.vm);
      }
    },
    onFocus() {
      this.$emit("focus");
      this.errors = [];
    },
    validate(modelValue: any) {
      return this.validateRules(modelValue);
    }
  }
});
</script>

<style></style>
