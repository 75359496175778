
import { defineComponent, reactive, watch } from "vue";

import VInput from "@/components/base/VInput.vue";
import VDropdownList from "@/components/base/VDropdownList.vue";

import { ModelValue } from "@/types/utils";

interface DropDownItem {
  value: string;
  text: string;
}

interface State {
  isExpanded: boolean;
  defaultText: string;
}

export default defineComponent({
  name: "VAutocompleteInput",
  inheritAttrs: false,
  components: {
    VInput,
    VDropdownList
  },
  props: {
    label: {
      type: String,
      default: "Label",
      required: false
    },
    modelValue: {
      type: String,
      default: "",
      required: true
    },
    items: {
      type: Array as () => any[],
      default: () => []
    },
    minLength: {
      type: Number,
      default: 0
    },
    rules: {
      type: Array as () => ((modelValue: ModelValue) => string | boolean)[],
      required: false,
      default: () => []
    },
    onBlurValidation: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const state = reactive<State>({
      isExpanded: false,
      defaultText: "Select an Item"
    });

    // const { isValid, errors, validateRules } = useInputValidation(props.rules);

    const onItemSelection = (item: any) => {
      context.emit("update:modelValue", item.value);
      state.isExpanded = false;
    };

    watch(
      () => props.items,
      (newItems, oldItems) => {
        if (newItems === oldItems) return;
        if (newItems.length > props.minLength) {
          state.isExpanded = true;
        } else if (newItems.length === props.minLength) {
          state.isExpanded = false;
        }
      }
    );

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        if (newValue.length === 0) {
          state.isExpanded = false;
        }
      }
    );

    return {
      state,
      onItemSelection
    };
  }
});
