
import { defineComponent, provide, ref } from "vue";

export default defineComponent({
  inheritAttrs: false,
  name: "VForm",
  setup() {
    const inputs = ref<any[]>([]);

    const register = (input: any) => {
      inputs.value.push(input);
    };

    const unregister = (currentInput: any) => {
      const inputIndex = inputs.value.findIndex((input: any) => {
        return input.uid === currentInput.uid;
      });

      if (inputIndex >= 0) {
        inputs.value.splice(inputIndex, 1);
      }
    };
    provide("register", register);
    provide("unregister", unregister);

    const validate = (): boolean => {
      let allValidated = true;

      inputs.value.forEach(input => {
        const isValidateRulesDefined = input?.ctx?.validate;
        if (isValidateRulesDefined) {
          const isInputValid = input.ctx.validate(input.data.vm);
          if (!isInputValid) {
            allValidated = false;
          }
        }
      });

      return allValidated;
    };

    return {
      validate
    };
  }
});
