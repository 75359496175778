
import { defineComponent } from "vue";

export default defineComponent({
  name: "VRadio",
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true
    },
    name: {
      type: String,
      default: "radio",
      required: true
    },
    selectedItem: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Label"
    }
  },
  computed: {
    isSelectedItem(): boolean {
      return this.selectedItem === this.modelValue;
    }
  }
});
