import { computed, reactive, ref, watch } from "vue";
import { Quotation, IncludeGST, CalculatorRepaymentFrequency } from ".";
import useApi from "@/composables/api/useApi";

const DEFAULT_INTEREST_RATE = 6.95;
const DEFAULT_LOAN_TERM_YEARS = 5;

const initialQuotation: Quotation = {
  purchasePrice: null,
  accessoriesCost: null,
  depositAmt: null,
  frequency: CalculatorRepaymentFrequency.Monthly,
  loanTerm: 0,
  interestRate: DEFAULT_INTEREST_RATE,
  custName: "",
  custContactNo: "",
  custEmail: "",
  prfContactTime: "",
  privacyDeclaration: false,
  optOut: false,
  referrerName: "",
  referrerContactNo: "",
  referrerEmail: "",
  comments: "",
  manufacturerName: "",
  referrerConsent: false,
  repaymentAmount: 0,
  totalCost: 0,
  totalInterest: 0,
  gstAmt: 0,
  isIncludeGST: null
};

const quotation = reactive({ ...initialQuotation });

// Refs
const loanTermSelected = ref<number | null>(DEFAULT_LOAN_TERM_YEARS);

const resetDefaults = () => {
  Object.assign(quotation, initialQuotation);
  loanTermSelected.value = DEFAULT_LOAN_TERM_YEARS;
};

const useBusinessQuoting = () => {
  const { api } = useApi();

  const isSubmitSuccess = ref(false);
  const isSubmitting = ref(false);
  const isError = ref(false);

  const loanAmount = computed(
    () =>
      (quotation.purchasePrice || 0) +
      (quotation.accessoriesCost || 0) -
      (quotation.depositAmt || 0)
  );

  const isDepAmountLimit = computed(
    () =>
      (quotation.depositAmt || 0) >
      (quotation.purchasePrice || 0) + (quotation.accessoriesCost || 0)
  );

  const isLoanAmountInLimit = computed(
    () => loanAmount.value >= 5000 && loanAmount.value <= 1000000
  );

  const gstAmount = computed(() =>
    ((((quotation.purchasePrice || 0) + (quotation.accessoriesCost || 0)) * 3) / 23).toFixed(2)
  );

  watch(gstAmount, newAmount => {
    if (newAmount) {
      quotation.gstAmt = parseFloat(newAmount);
    }
  });

  const submitQuote = async () => {
    const {
      purchasePrice,
      accessoriesCost,
      depositAmt,
      frequency,
      loanTerm,
      interestRate,
      custName,
      custContactNo,
      custEmail,
      prfContactTime,
      privacyDeclaration,
      optOut,
      referrerName,
      referrerContactNo,
      referrerEmail,
      comments,
      manufacturerName,
      referrerConsent,
      repaymentAmount,
      totalInterest,
      totalCost,
      gstAmt,
      isIncludeGST
    } = quotation;

    const submitQuotePayload = {
      purchasePrice: purchasePrice,
      accessoriesCost: accessoriesCost || 0,
      depositAmt: depositAmt || 0,
      frequency,
      loanTerm,
      interestRate,
      custName,
      custContactNo,
      custEmail,
      prfContactTime,
      privacyDeclaration,
      optOut,
      referrerName,
      referrerContactNo,
      referrerEmail,
      comments,
      manufacturerName,
      referrerConsent,
      repaymentAmount: repaymentAmount || 0,
      totalInterest: totalInterest || 0,
      totalCost: totalCost || 0,
      gstAmt: gstAmt || 0,
      isIncludeGST
    };

    isSubmitSuccess.value = false;
    isError.value = false;
    isSubmitting.value = true;
    try {
      await api.post("commloan/v1/quote", submitQuotePayload);
      isSubmitSuccess.value = true;
    } catch (err) {
      isError.value = true;
    }
    isSubmitting.value = false;
  };

  return {
    quotation,
    loanTermSelected,
    loanAmount,
    submitQuote,
    resetDefaults,
    isSubmitSuccess,
    gstAmount,
    isError,
    isSubmitting,
    isDepAmountLimit,
    isLoanAmountInLimit
  };
};

export default useBusinessQuoting;
