
import { defineComponent } from "vue";

export default defineComponent({
  name: "VDialog",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
