<template>
  <v-form ref="form" autocomplete="off" class="pt-4 pb-10">
    <p class="mt-4">
      Please enter the customer's details below.
    </p>

    <v-input
      autocomplete="nope"
      v-model="quotation.custName"
      data-testid="customer-name"
      :rules="[
        v => !!v || 'Customer name is required',
        specialCharacterRule,
        v => v.length <= 150 || 'Maximum length is 150'
      ]"
      label="Full name"
    ></v-input>
    <v-input
      autocomplete="nope"
      type="tel"
      pattern="[0-9]"
      data-testid="cust-contact-number"
      v-model="quotation.custContactNo"
      :rules="[
        v => !!v || 'Contact number is required',
        v => /^\d+$/.test(v) || 'Please enter a valid contact number',
        v => v.length >= 9 || 'Please enter a valid contact number',
        v => v.length <= 11 || 'Please enter a valid contact number'
      ]"
      label="Contact number"
    ></v-input>
    <v-input
      autocomplete="nope"
      type="email"
      data-testid="cust-email"
      :rules="[
        v => !!v || 'Email is required',
        v => validateEmail(v) || 'Please enter a valid email',
        v => v.length <= 160 || 'Maximum length is 160'
      ]"
      v-model="quotation.custEmail"
      label="Email address"
    ></v-input>
    <v-input
      autocomplete="nope"
      placeholder="E.g. 10am"
      data-testid="contact-time"
      label="Preferred contact time (optional)"
      :rules="[specialCharacterRule]"
      v-model="quotation.prfContactTime"
    ></v-input>
    <PrivacyDeclaration
      class="mt-4"
      v-model:privacy="quotation.privacyDeclaration"
      v-model:marketing="quotation.optOut"
    />

    <p class="text-error">
      {{ privacyError }}
    </p>

    <div class="flex flex-col items-center mt-8 sm:flex-row-reverse sm:justify-around">
      <v-button data-testid="next-btn" @click.prevent="goToNextStep" class="w-64"
        >Next: Referrer details</v-button
      >
      <v-button
        data-testid="back-btn"
        type="secondary"
        @click.prevent="$router.go(-1)"
        class="w-64 mt-4 sm:mt-0"
        >Back</v-button
      >
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { QuotingFormStep } from "@/composables/quoting/";
import useBusinessQuoting from "@/composables/quoting/useBusinessQuoting.ts";

import { FormRef } from "@/types/base/";

import { scrollToTop, validateEmail, validateName } from "@/util/helpers";
import { useRouter } from "vue-router";

import PrivacyDeclaration from "@/components/shared/PrivacyDeclaration.vue";

export default defineComponent({
  name: "QuoteDetailsStep",
  components: {
    PrivacyDeclaration
  },
  setup() {
    const { quotation } = useBusinessQuoting();

    const form = ref<null | FormRef>(null);
    const privacyError = ref("");

    const specialCharacterRule = (v: any) => {
      if (!v) {
        return true;
      } else {
        return (
          /^[a-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžßÇ \t.,_@?!/.:;'"&$%*()+=\n\r\-–]+$/i.test(
            v
          ) || "Please enter a valid character"
        );
      }
    };

    watch(
      () => quotation.privacyDeclaration,
      () => {
        privacyError.value = "";
      }
    );

    const router = useRouter();

    const goToNextStep = async () => {
      if (form.value?.validate()) {
        if (quotation.privacyDeclaration) {
          privacyError.value = "";
          router.push(`/create-quote/${QuotingFormStep.ReferrerDetails}`);
        } else {
          privacyError.value = "Please select the checkbox";
        }
      }
    };

    return {
      quotation,
      goToNextStep,
      form,
      validateName,
      validateEmail,
      privacyError,
      specialCharacterRule
    };
  },
  methods: {
    validate() {
      this.validateName;
    }
  },
  created() {
    scrollToTop();
  }
});
</script>

<style></style>
