import { CalculatorRepaymentFrequency } from "@/composables/quoting/";

export function calculateLoanTerm(
  years: number,
  repaymentFrequency: string
): number {
  if (repaymentFrequency === CalculatorRepaymentFrequency.Weekly) {
    return years * 52; // Weekly - Total number of weeks
  } else if (repaymentFrequency === CalculatorRepaymentFrequency.Fortnightly) {
    return years * 26; // Fortnightly - Total number of fortnights
  } else if (repaymentFrequency === CalculatorRepaymentFrequency.Monthly) {
    return years * 12; // Monthly - Total number of months
  } else {
    return 0;
  }
}
